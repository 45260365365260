// ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

// Utility function to check if user is authenticated
const isAuthenticated = () => {
  // Example: Check if the authentication token exists in localStorage
  const token = localStorage.getItem('accessToken');
  return !!token; // Return true if token exists
};

function ProtectedRoute({ children }) {
  // Check if user is authenticated
  const isLoggedIn = isAuthenticated();

  if (!isLoggedIn) {
    // Redirect to home page if not authenticated
    return <Navigate to="/" replace />;
  }

  // If authenticated, render the children components (like profile page)
  return <>{children}</>;
}

export default ProtectedRoute;
