import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {RootState as AppState} from "../store";

export interface createNFTState {
    showCreateModal: boolean,
    goToCreatedTab:boolean,
    showCreateModalAfterLogin:boolean
}

const initialState: createNFTState = {
    showCreateModal:false ,
    goToCreatedTab:false,
    showCreateModalAfterLogin:false
}

export const createNFTSlice = createSlice({
    name: 'createNFT',
    initialState,
    reducers: {
        setShowCreateModal: (state, action: PayloadAction<boolean>) => {
            state.showCreateModal = action.payload;
        },
        setGoToCreatedTab: (state, action: PayloadAction<boolean>) => {
            state.goToCreatedTab = action.payload;
        },
        setShowCreatedModalAfterLogin: (state, action: PayloadAction<boolean>) => {
            state.showCreateModalAfterLogin = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowCreateModal } = createNFTSlice.actions
export const { setGoToCreatedTab } = createNFTSlice.actions
export const { setShowCreatedModalAfterLogin } = createNFTSlice.actions


export const selectShowCreateModal = (state:AppState) => state.createNFT.showCreateModal;
export const selectGoToCreatedTab = (state:AppState) => state.createNFT.goToCreatedTab;
export const selectShowCreateModalAfterLogin = (state:AppState) => state.createNFT.showCreateModalAfterLogin;


export default createNFTSlice.reducer
