import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {RootState as AppState} from "../store";
import {array, bool, boolean} from "yup";

export interface accountState {
    isLogin: boolean,
    loginType: string,
    userData: object,
    showLogin: boolean,
    goToGiftTab: boolean,
    emailOrPhoneValidated: boolean,
    loadingSummary: boolean,
    showSharePopOver: boolean,
    creditCode: string
}

const initialState: accountState = {
    isLogin: false,
    loginType: 'phone',
    userData: {
        phone: "",
        walletAddress: "",
        userName: "",
        walletBalance: ""
    },
    showLogin: false,
    goToGiftTab: false,
    emailOrPhoneValidated: false,
    loadingSummary: true,
    showSharePopOver: false,
    creditCode: ""
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setIsLogin: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload;
        },
        setLoginType: (state, action: PayloadAction<string>) => {
            state.loginType = action.payload;
        },
        setUserData: (state, action: PayloadAction<object>) => {
            state.userData = action.payload;
        },
        setLoadingSummary: (state, action: PayloadAction<boolean>) => {
            state.loadingSummary = action.payload
        },
        setShowLogin: (state, action: PayloadAction<boolean>) => {
            state.showLogin = action.payload;
        },
        setGotoGiftTab: (state, action: PayloadAction<boolean>) => {
            state.goToGiftTab = action.payload;
        },
        setEmailOrPhoneValidated: (state, action: PayloadAction<boolean>) => {
            state.emailOrPhoneValidated = action.payload;
        },
        setShowSharePopover: (state, action: PayloadAction<boolean>) => {
            state.showSharePopOver = action.payload;
        },
        setCreditCode: (state, action: PayloadAction<string>) => {
            state.creditCode = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {setIsLogin} = accountSlice.actions
export const {setLoginType} = accountSlice.actions;
export const {setUserData} = accountSlice.actions;
export const {setLoadingSummary} = accountSlice.actions;
export const {setShowLogin} = accountSlice.actions;
export const {setGotoGiftTab} = accountSlice.actions;
export const {setEmailOrPhoneValidated} = accountSlice.actions;
export const {setShowSharePopover} = accountSlice.actions;
export const {setCreditCode} = accountSlice.actions;


export const selectIsLogin = (state: AppState) => state.account.isLogin;
export const selectLoginType = (state: AppState) => state.account.loginType;
export const selectUserData = (state: AppState) => state.account.userData;
export const selectLoadingSummary = (state: AppState) => state.account.loadingSummary;

export const selectShowLogin = (state: AppState) => state.account.showLogin;
export const selectGotoGiftTab = (state: AppState) => state.account.goToGiftTab;
export const selectEmailOrPhoneValidated = (state: AppState) => state.account.emailOrPhoneValidated;
export const selectShowSharePopover = (state: AppState) => state.account.showSharePopOver;
export const selectCreditCode = (state: AppState) => state.account.creditCode;


export default accountSlice.reducer