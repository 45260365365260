import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {RootState as AppState} from "../store";

export interface buySlice {
    showBuyModal: boolean,
    showBuyModalAfterLogin: boolean,
    scrollToBuySec: boolean,
    startDonationProcessAfterLogin: boolean,
    showWalletsAfterLoginBuyNFT: boolean,
    checkIsUserCapableToBuy: object,
    requestPredictionAfterLogin: boolean,
}

const initialState: buySlice = {
    showBuyModal: false,
    showBuyModalAfterLogin: false,
    scrollToBuySec: false,
    startDonationProcessAfterLogin: false,
    showWalletsAfterLoginBuyNFT: false,
    checkIsUserCapableToBuy: {
        shouldCheckLater: false,
        theNftOwnerShouldBeChecked: {},
        momentType: "single"
    },
    requestPredictionAfterLogin: false,
}

export const buySlice = createSlice({
    name: 'buyModal',
    initialState,
    reducers: {
        setShowBuyModal: (state, action: PayloadAction<boolean>) => {
            state.showBuyModal = action.payload;
        },
        setShowBuyModalAfterLogin: (state, action: PayloadAction<boolean>) => {
            state.showBuyModalAfterLogin = action.payload;
        },
        setScrollToBuySec: (state, action: PayloadAction<boolean>) => {
            state.scrollToBuySec = action.payload;
        },
        setStartDonationProcessAfterLogin: (state, action: PayloadAction<boolean>) => {
            state.startDonationProcessAfterLogin = action.payload;
        },
        setShowWalletsAfterLoginBuyNFT: (state, action: PayloadAction<boolean>) => {
            state.showWalletsAfterLoginBuyNFT = action.payload;
        },
        setCheckIsUserCapableToBuy: (state, action: PayloadAction<object>) => {
            state.checkIsUserCapableToBuy = action.payload;
        },
        setRequestPredictionAfterLogin: (state, action: PayloadAction<boolean>) => {
            state.requestPredictionAfterLogin = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {setShowBuyModal} = buySlice.actions
export const {setShowBuyModalAfterLogin} = buySlice.actions
export const {setScrollToBuySec} = buySlice.actions
export const {setStartDonationProcessAfterLogin} = buySlice.actions

export const {setShowWalletsAfterLoginBuyNFT} = buySlice.actions
export const {setCheckIsUserCapableToBuy} = buySlice.actions

export const {setRequestPredictionAfterLogin} = buySlice.actions


export const selectShowBuyModal = (state: AppState) => state.buyModal.showBuyModal;
export const selectShowBuyModalAfterLogin = (state: AppState) => state.buyModal.showBuyModalAfterLogin;
export const selectScrollToBuySec = (state: AppState) => state.buyModal.scrollToBuySec;
export const selectStartDonationProcessAfterLogin = (state: AppState) => state.buyModal.startDonationProcessAfterLogin;

export const selectShowWalletsAfterLoginBuyNFT = (state: AppState) => state.buyModal.showWalletsAfterLoginBuyNFT;
export const selectCheckIsUserCapableToBuy = (state: AppState) => state.buyModal.checkIsUserCapableToBuy;

export const selectRequestPredictionAfterLogin = (state: AppState) => state.buyModal.requestPredictionAfterLogin;


export default buySlice.reducer
