import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {RootState as AppState} from "../store";

export interface languageState {
    lang: string
}

const initialState: languageState = {
    lang: "fa",
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.lang = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setLanguage } = languageSlice.actions

export const selectLanguage = (state:AppState) => state.language.lang;


export default languageSlice.reducer