import { configureStore } from '@reduxjs/toolkit'
import languageSlice from "./slices/languageSlice";
import accountSlice from "./slices/accountSlice";
import showDrawerSlice from "./slices/showDrawersSlice";
import toastSlice from "./slices/toastSlice";
import createNFTSlice from "./slices/createNFTSlice";
import BuyModalSlice from "./slices/buySlice";
export const store = configureStore({
    reducer: {
        language: languageSlice,
        account:accountSlice,
        showDrawers:showDrawerSlice,
        toast:toastSlice,
        createNFT:createNFTSlice,
        buyModal:BuyModalSlice
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch