import './instrument';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import { MetronicI18nProvider } from '../_metronic/i18n/Metronici18n';
import { store } from './redux/store.tsx';
import AppRoutes from './routing/AppRoutes';
import '../_metronic/assets/sass/style.scss';
import '../_metronic/assets/sass/plugins.scss';
import './assets/font/SansWeb.woff';
import './assets/styles/index.scss';

const container = document.getElementById('root');

// ...

const tagManagerArgs = {
  gtmId: 'GTM-5BPQ7NG',
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/sw.js').then((registration) => {
//       console.log('SW registered: ', registration);
//     }).catch((registrationError) => {
//       console.log('SW registration failed: ', registrationError);
//     });
//   });
// }

export default function App() {
  return (
    <Provider store={store}>
      <MetronicI18nProvider>
        <AppRoutes />
      </MetronicI18nProvider>
    </Provider>
  );
}

if (container) {
  createRoot(container).render(
    <App />,
  );
}
