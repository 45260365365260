import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {RootState as AppState} from "../store";

export interface languageState {
    showHeaderDrawer:boolean,
    showExploreDrawer:boolean,
    showFollowDrawer:boolean,
}

const initialState: languageState = {
    showHeaderDrawer: false,
    showExploreDrawer:false,
    showFollowDrawer:false
}

export const showDrawerSlice = createSlice({
    name: 'showDrawers',
    initialState,
    reducers: {
        setShowHeaderDrawer: (state, action: PayloadAction<boolean>) => {
            state.showHeaderDrawer = action.payload;
        },
        setShowExploreDrawer: (state, action: PayloadAction<boolean>) => {
            state.showExploreDrawer = action.payload;
        },
        setShowFollowDrawer: (state, action: PayloadAction<boolean>) => {
            state.showFollowDrawer = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowHeaderDrawer } = showDrawerSlice.actions
export const { setShowExploreDrawer } = showDrawerSlice.actions
export const { setShowFollowDrawer } = showDrawerSlice.actions

export const selectShowHeaderDrawer = (state:AppState) => state.showDrawers.showHeaderDrawer;
export const selectShowExploreDrawer = (state:AppState) => state.showDrawers.showExploreDrawer;
export const selectShowFollowDrawer = (state:AppState) => state.showDrawers.showFollowDrawer;


export default showDrawerSlice.reducer