/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { lazy } from 'react';
import ProtectedRoute from './ProtectedRoute.jsx';

const App = lazy(() => import('../App'));

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const { PUBLIC_URL } = import.meta.env;

export default function AppRoutes() {
  const HomePage = lazy(() => import('../pages/sportCategory/homePage/index'));
  const Explore = lazy(() => import('../pages/sportCategory/explore/Explore'));

  const Nft = lazy(() => import('../pages/sportCategory/nft/Nft'));

  const User = lazy(() => import('../pages/sportCategory/user/User'));
  const Profile = lazy(() => import('../pages/sportCategory/profile/Profile'));

  const PaymentReceipt = lazy(() => import('../pages/sportCategory/paymentReceipt/index'));
  const Faq = lazy(() => import('../pages/sportCategory/faq/Faq'));
  const TermsOfService = lazy(() => import('../pages/sportCategory/termsOfService/TermsOfService'));
  const AboutUs = lazy(() => import('../pages/sportCategory/about-Us/AboutUs'));
  const Organizer = lazy(() => import('../pages/sportCategory/club/index'));
  const Collab = lazy(() => import('../pages/sportCategory/collab/index'));
  const ClubsList = lazy(() => import('../pages/sportCategory/ClubsList/index'));

  const ContactUs = lazy(() => import('../pages/sportCategory/contact-us/index'));

  const AboutNFT = lazy(() => import('../pages/sportCategory/about-nft/index'));

  const CatalogQrCodeVoice = lazy(() => import('../pages/sportCategory/catalogQrCodeVoice/index'));

  const MomentCertificate = lazy(() => import('../pages/sportCategory/momentCertificate/index.jsx'));

  const Learning = lazy(() => import('../pages/sportCategory/learning/index'));

  const Prediction = lazy(() => import('../pages/sportCategory/prediction/index'));

  const FCGift = lazy(() => import('../pages/sportCategory/fc-gift/index'));

  const WaitList = lazy(() => import('../pages/sportCategory/waitList/index'));

  const ClubsLanding = lazy(() => import('../pages/sportCategory/clubsLanding/index'));

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<HomePage />} />

          <Route path="/waitlist" element={<WaitList />} />

          <Route path="/explore" element={<Explore />} />

          {/* <Route path="/clubs/:id" element={<Campaign />} /> */}
          {/* <Route path="/clubs/:name/:id/*" element={<Campaign />} /> */}

          <Route path="/users/:username/*" element={<User />} />

          <Route
            path="/profile/*"
            element={(
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
              )}
          />

          <Route path="/nfts/:id" element={<Nft />} />
          <Route path="/nfts/:id/editions/:editionId" element={<Nft />} />

          <Route path="/payment-receipt/:id" element={<PaymentReceipt />} />

          <Route path="/faq" element={<Faq />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-nft" element={<AboutNFT />} />

          <Route path="/catalog-qrcode-voice/1709116762409-iNCF7yAfXWXTiLzjazMxH7eoiryift0685ygRMsVXZbDdtyOJTgLe18jCr00b2DPko5ats2xLRNBoQ9QdB5M6yqF9qy1IQdhqwxm" element={<CatalogQrCodeVoice />} />

          <Route path="/clubs" element={<ClubsList />} />
          <Route path="/clubs/:id" element={<Organizer />} />
          <Route path="/collab" element={<Collab />} />

          <Route path="/moment-certificate/:momentId" element={<MomentCertificate />} />
          <Route path="/moment-certificate/:momentId/editions/:editionId" element={<MomentCertificate />} />

          <Route path="/learning" element={<Learning />} />

          <Route path="/prediction" element={<Prediction />} />

          <Route path="/fc-gift" element={<FCGift />} />

          <Route path="/clubs-landing" element={<ClubsLanding />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}
