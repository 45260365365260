import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {RootState as AppState} from "../store";

export interface toastState {
    showToast: boolean,
    toastMessage:string,
    toastStatus:number
}

const initialState: toastState = {
    showToast: false,
    toastMessage:"",
    toastStatus:1
}

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setShowToast: (state, action: PayloadAction<boolean>) => {
            state.showToast = action.payload;
        },
        setToastMessage: (state, action: PayloadAction<string>) => {
            state.toastMessage = action.payload;
        },
        setToastStatus: (state, action: PayloadAction<number>) => {
            state.toastStatus = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setShowToast } = toastSlice.actions
export const { setToastMessage } = toastSlice.actions
export const { setToastStatus } = toastSlice.actions


export const selectShowToast = (state:AppState) => state.toast.showToast;
export const selectToastMessage = (state:AppState) => state.toast.toastMessage;
export const selectToastStatus = (state:AppState) => state.toast.toastStatus;


export default toastSlice.reducer